export const menus = [
  {
    title: "dashboard",
    icon: "uil uil-dashboard fs-4 me-2",
    url: { name: "Dashboard" },
  },
  {
    title: "leads",
    icon: "uil uil-users-alt fs-4 me-2",
    submenus: {
      users: { name: "Users" },
      suppliers: { name: "Suppliers" },
      Clients: { name: "Clients" },
    },
  },
  {
    title: "inventory",
    icon: "uil uil-apps fs-4 me-2",
    submenus: {
      categories: { name: "Categories" },
      products: { name: "Products" },
      purchase_items: {
        name: "ItemsReceive",
        params: { action: "new" },
      },
      transfer_items: {
        name: "ItemsTransfer",
        params: { action: "new" },
      },
      requisitions: {
        name: "RequisitionCreator",
        params: { action: "new" },
      },
      /*adjustments: {
        name: "Adjustments",
        params: { action: "new" },
      }, */
    },
  },
  {
    title: "point_of_sales",
    icon: "uil uil-shopping-cart fs-4 me-2",
    submenus: {
      menu_items: { name: "Products", query: { origin: "menu" } },
      items_categories: { name: "Categories", query: { origin: "menu" } },
      tables: { name: "Postables" },
      /*orders: { name: "Dashboard" }, */
    },
  },
  {
    title: "finance",
    icon: "uil uil-money-bill-stack fs-4 me-2",
    submenus: {
      accounts: { name: "FinanceAccounts" },
      expenses: { name: "FinanceExpenses" },
    },
  },
  {
    title: "assets",
    icon: "uil uil-archway fs-4 me-2",
    submenus: {
      categories: { name: "AssetCategories" },
      assets_list: { name: "AssetsList" },
      amortized_assets: { name: "FinanceExpenseCategories" },
    },
  },
  {
    title: "Accomodation",
    icon: "uil uil-bed-double fs-4 me-2",
    submenus: {
      rooms: { name: "Products", query: { origin: "service" } },
    },
  },
  {
    title: "reports",
    icon: "uil uil-file-blank fs-4 me-2",
    notifications: ["requisitions"],
    submenus: {
      purchases_report: { name: "ReceiveReport" },
      transfers_report: { name: "TransferReport" },
      requisitions_report: {
        name: "RequisitionsReport",
        notification: "requisitions",
      },
      products_report: { name: "TrackerReport" },
      expenses_report: { name: "ExpensesReport" },
      /* adjustments_report: { name: "AdjustmentsReport" }, */
      sales_report: { name: "SalesReport" },
      item_sales_report: { name: "DetailsReport" },
      customers_balance: { name: "CustomerBalanceReport" },
      balance_transfer: { name: "AccountsTransfers" },
      account_transactions: { name: "AccountTransactions" },
      performance_report: { name: "WaitersPerformance" },
    },
  },
  {
    title: "settings",
    icon: "uil uil-cog fs-4 me-2",
    submenus: {
      users_roles: { name: "UserRoles" },
      items_units: { name: "ItemsUnits" },
      departments: { name: "Departments" },
      expenses_categories: { name: "FinanceExpenseCategories" },
    },
  },
];
